<template>
  <v-server-table
        :columns="columns"
        :options="options" 
        ref="table"
        @loaded="onLoaded(0)"
        @loading="onLoaded(1)"
    >
        <div slot="action" slot-scope="{ row }">
            <btn-action :row-data="row"
            :patient-vue="patientVue" :storeObject="$store"></btn-action>
        </div>
        <div v-if="isLoading" slot="afterBody" >
            <h6 class="txt"> loading..</h6>
        </div>
    </v-server-table>
</template>

<script>
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PatientRepository = RepositoryFactory.get("patient");
import BtnAction from "./../_subcomponents/BtnAction";


export default {
    name: 'patient-server-table',
    components: {
        BtnAction
    },
    props: {
        patientVue: {
            type: Object
        }
    },
    data () {
        var vx = this
        return {
            tableHeaderClass: 'blocked',
            tableBodyClass: 'vuetable-semantic-no-top blocked',
            isLoading: false,
            columns: ['id', 'patientName','dateOfBirth','mobilePhone1','email', 'jenisKelamin.name', 'agama.name', 'golDarah', 'etnis.name', 'pendidikan', 'ssn','medicalNo', 'action'],
            options: {
                texts: {
                    filterBy: 'Cari',
                },
                filterable: ['patientName','mobilePhone1','email', 'jenisKelamin.name','agama.name', 'golDarah', 'etnis.name', 'pendidikan', 'ssn','medicalNo', 'medicalNo'],
                headings: {
                    id: 'ID',
                    patientName: "Nama",
                    dateOfBirth: "Tanggal Lahir",
                    mobilePhone1: "No HP",
                    email: "Email",
                    "jenisKelamin.name": "Jenis Kelamin",
                    "agama.name": 'Agama',
                    golDarah: 'Golongan Darah',
                    "etnis.name": 'Etnis',
                    pendidikan: 'Pendidikan',
                    ssn: 'SSN',
                    medicalNo: "No Rekam Medik",
                    action: 'Actions'
                },
                dateColumns: ['createdDate', 'lastModifiedDate' ],
                toMomentFormat: 'YYYY-MM-DD',
                requestFunction(data) {
                    if (this.abortController) {
                        this.abortController.abort()
                    }
                    let params = {};
                    params.page = data.page-1;
                    params.size = data.limit;
                    params.sort = 'id'
                    this.isLoaded = false

                    let methodStr = `fetch`
                    if(_.isEmpty(data.query)){
                        vx.modeSearch = false;
                        
                        // return PatientRepository.fetch(params).catch(function (e) {
                        //     this.dispatch('error', e);
                        // });
                    }else{
                        methodStr = `search`
                        vx.modeSearch = true;
                        params.query = helpers.filterTable(data.query);
                        
                    }
                    
                    const repo = PatientRepository[methodStr](params)
                    this.abortController = repo.next().value
                    const reposi = repo.next().value

                    repo.next()
                    return reposi.catch(e => {
                        console.log(e , "Request Function Patient Server Table");
                    })
                },
                responseAdapter: (resp) => {  
                    return {
                        data: resp.data,
                        count: resp.headers['x-total-count'] ? resp.headers['x-total-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
                    }
                },
                rowClassCallback(row) {
                    return  (row.isNewData) ? 'highlight':'';
                },
                filterByColumn: true,
                sortable:[],
                templates: {
                    createdDate(h, row) {
                        return moment(row.createdDate).add(7, "hours").format("DD-MMM-YYYY");
                    },
                    authorities(h, row) {
                        let text = '<span class="p-2 mt-1 badge badge-info">'+row.authorities+'</span> <br/>'
                        return row.authorities
                    },
                    activated(h, row) {
                        return row.activated == true ? 'True' : 'False'
                    },
                },
                stickyHeader: true
            },
            tableData: [],
            abortController: null
        }
    },
    methods: {
        onLoaded: function(t) {
            t == 0 ? this.isLoading = false : this.isLoading = true  
        },
    }

}
</script>