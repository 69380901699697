<template>
  <v-server-table
        :columns="columns"
        :options="options" 
        ref="table"
        @loaded="onLoaded(0)"
        @loading="onLoaded(1)"
    >
        <div slot="action" slot-scope="{ row }">
            <btn-action :row-data="row"
            :patient-vue="patientVue" :storeObject="$store"></btn-action>
        </div>
        <div v-if="isLoading" slot="afterBody" >
            <h6 class="txt"> loading..</h6>
        </div>
    </v-server-table>
</template>

<script>
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const UserPatientRepository = RepositoryFactory.get("userPatient");
import BtnAction from "./../_subcomponents/BtnAction2";


export default {
    name: 'patient-server-table',
    components: {
        BtnAction
    },
    data () {
        var vx = this
        return {
            patientVue: vx,
            isLoading: false,
            columns: ['id','phoneNumber','userId', 'action'],
            options: {
                texts: {
                    filterBy: 'Cari',
                },
                filterable: ['phoneNumber' , 'userId'],
                headings: {
                    phoneNumber: "No HP / Login",
                    id: "ID",
                    userId: "User Id",
                    action: 'Actions'
                },
                dateColumns: ['createdDate', 'lastModifiedDate' ],
                toMomentFormat: 'YYYY-MM-DD',
                requestFunction(data) {
                    let params = {};
                    params.page = data.page-1;
                    params.size = data.limit;
                    params.sort = 'id'
                    this.isLoaded = false
                    if(_.isEmpty(data.query)){
                        vx.modeSearch = false;
                        return UserPatientRepository.fetch(params).catch(function (e) {
                            this.dispatch('error', e);
                        });
                    }else{
                        vx.modeSearch = true;
                        // params.query = helpers.filterTable(data.query);
                        if (data.query.phoneNumber) {
                            params["phoneNumber.contains"] = data.query.phoneNumber
                        }
                        if (data.query.userId) {
                            params["userId.equals"] = data.query.userId
                        }
                        return UserPatientRepository.fetch(params).catch(function (e) {
                            vx.showToastError('Error Get Data');
                            this.dispatch('error', e);
                        });
                    }
                },
                responseAdapter: (resp) => {  
                    return {
                    data: resp.data,
                    count: resp.headers['x-total-count'] ? resp.headers['x-total-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
                    }
                },
                rowClassCallback(row) {
                    return  (row.isNewData) ? 'highlight':'';
                },
                filterByColumn: true,
                sortable:[],
                templates: {
                    createdDate(h, row) {
                        return moment(row.createdDate).add(7, "hours").format("DD-MMM-YYYY");
                    },
                    activated(h, row) {
                        return row.activated == true ? 'True' : 'False'
                    },
                }
            },
            tableData: [],
        }
    },
    methods: {
        onLoaded: function(t) {
            t == 0 ? this.isLoading = false : this.isLoading = true  
        },
    }

}
</script>