<template>
  <div class="text-center">
    <template>
      <button
        @click="resetPassword()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Change Password"
      >
        <i class="la la-lock"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PatientRepository = RepositoryFactory.get("patient");
const PatientUserPatientRepository = RepositoryFactory.get("patientUserPatient")
const UserRepository = RepositoryFactory.get("user");
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      permissionUpdate: 'PatientResource.PUT.Patient',
      permissionDelete: 'PatientResource.DELETE.Patient.id',
      permissionUpdateLocation: 'MRLocationResource.POST.MRLocation'
    }
  },
  props: {
    rowData: { type: Object },
    storeObject: {},
    patientVue: { type: Object },
  },
  computed: {
    dataEdit: function () {
      return this.storeObject.state.MasterStore.dataEdit;
    }
  },
  methods: {
    async resetPassword() {
      try {
        const { data } = await UserRepository.search(this.rowData.userId)
        if(data.length){
          let routeData = this.$router.resolve({name: 'user-management'});
          localStorage.setItem('login', data[0].login);
          window.open(routeData.href, '_blank');
        } else {
          throw 'User tidak ditemukan'
        }
      } catch (error) {
        console.log(error , "error resetPassword");
        toastr.error(error);
      }
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  },
  mounted() {
  }
};
</script>
