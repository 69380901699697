<template>
  <div class="text-center">
    <template v-if="checkPermission(permissionUpdate)">
      <button
        @click="editData()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission(permissionDelete)">
      <button
        @click="deleteData()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
    <template v-if="checkPermission(permissionUpdateLocation)">
      <button
        @click="editLocation()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Update Lokasi"
        :disabled="!rowData.medicalNo"
        :class="[!rowData.medicalNo ? 'disable-icon' : '']"
      >
        <i class="la la-map-marker"></i>
      </button>
    </template>
    <template>
      <button
        @click="resetPassword()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Login"
      >
        <i class="la la-lock"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PatientRepository = RepositoryFactory.get("patient");
const PatientUserPatientRepository = RepositoryFactory.get("patientUserPatient")
const UserRepository = RepositoryFactory.get("user");
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      permissionUpdate: 'PatientResource.PUT.Patient',
      permissionDelete: 'PatientResource.DELETE.Patient.id',
      permissionUpdateLocation: 'MRLocationResource.POST.MRLocation'
    }
  },
  props: {
    rowData: { type: Object },
    storeObject: {},
    patientVue: { type: Object },
  },
  computed: {
    dataEdit: function () {
      return this.storeObject.state.MasterStore.dataEdit;
    }
  },
  methods: {
    editLocation() {
      this.patientVue.dataEditLocation = { patient : this.rowData};
      this.patientVue.isShowModalLocation = true;
    },
    deleteData() {
      this.storeObject.dispatch("MasterStore/deleteByIdServer", { deleteRepository: PatientRepository.deletePatient, id: this.rowData.id, table: this.$parent });
    },
    editData() {
      this.storeObject.commit("MasterStore/SET_EDIT_DATA", this.rowData);
      this.storeObject.dispatch("MasterStore/getPatientUserPatient", this.rowData)
      this.storeObject.commit("MasterStore/SHOW_MODAL");
      // new Promise((resolve, reject) => {
      //   PatientUserPatientRepository.get({'patientId.equals': patientId}, resolve, reject)
      // })
      // .then(({ data }) => {
      //   this.userPatient = data
      // })
      // .catch(error => {
      //   console.log(error)
      // })
      // this.$emit('event')
    },
    resetPassword() {
      new Promise((resolve, reject) => {
          PatientUserPatientRepository.get({ 'patientId.equals': this.rowData.id }, resolve, reject)
      })
          .then(({ data }) => {
            let user = data.filter(x => x.familyType == 'DEFAULT')
            if(user.length != 0 ) {
              return UserRepository.search(user[0].userId)
            }
          })
          .then(({ data }) => {
            if(data) {
              let routeData = this.$router.resolve({name: 'user-management'});
              localStorage.setItem('login', data[0].login);
              window.open(routeData.href, '_blank');
            } else {
              toastr.error('Pasien tidak memiliki user')
            }
          })
          .catch(error => {
              toastr.error('Pasien tidak memiliki user')
          })
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  },
  mounted() {
  }
};
</script>
